import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet } from 'react-native-web';
import { useWalletBalance, useWalletAddress } from '../hooks/wallet';
import Stakewiz from '../api/stakewiz';
import Solana from '../api/solana';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, Keypair, StakeProgram, ComputeBudgetProgram, LAMPORTS_PER_SOL, Transaction } from '@solana/web3.js';

export default function StakeCard({ txHash, setTxHash, result, setResult }) {
    const pubkey = useWalletAddress();
    const balance = useWalletBalance();
    const [userBalance, setUserBalance] = useState(balance);
    const [stakeAmount, setStakeAmount] = useState('');
    const [apy, setApy] = useState(8.0);
    const { publicKey, sendTransaction } = useWallet();
    const { connection } = useConnection();
    const apyFetched = useRef(false);
    const voteAccount = "oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe";
    const solana = new Solana("https://mainnet.helius-rpc.com/?api-key=26dd16db-a14e-4393-a04f-fe0fc72db377");


    useEffect(() => {
        if (!apyFetched.current) {
            Stakewiz.fetchApy()
                .then(res => res.json())
                .then(res => {
                    setApy(res.total_apy);
                    apyFetched.current = true;
                });
        }
    }, []);

    useEffect(() => {
        const fetchBalance = async () => {
            const balance = await connection.getBalance(publicKey);
            setUserBalance(balance);
        };

        if (publicKey === null) {
            setStakeAmount(0);
            setUserBalance(0);
        } else {
            fetchBalance();
        }
    }, [publicKey, connection]);

    const handleMaxPress = () => {
        if (pubkey == null) {
            setStakeAmount(0)
        } else {
            let minBalanceForRentExemption = 0;
            solana.getMinimumBalanceForRentExemption()
                .then(res => {
                    minBalanceForRentExemption = res / 1e9;
                });
            const stakeAmount = balance - (minBalanceForRentExemption + 0.00001);
            if (stakeAmount > 0) {
                setStakeAmount(stakeAmount);
            }

        }
    };

    const handleStakePress = useCallback(async () => {
        try {
            const stakeAccount = Keypair.generate();
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            const stakeAccountIX = StakeProgram.createAccount({
                fromPubkey: publicKey,
                stakePubkey: stakeAccount.publicKey,
                authorized: {
                    staker: publicKey,
                    withdrawer: publicKey,
                },
                lamports: stakeAmount * LAMPORTS_PER_SOL,
            });
            const delegateIX = StakeProgram.delegate({
                stakePubkey: stakeAccount.publicKey,
                authorizedPubkey: publicKey,
                votePubkey: new PublicKey(voteAccount),
            });
            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight }
            } = await connection.getLatestBlockhashAndContext();
            const transaction = new Transaction({
                feePayer: publicKey,
                blockhash,
                lastValidBlockHeight,
            })
                .add(priorityFeeIX)
                .add(stakeAccountIX)
                .add(delegateIX);
            transaction.partialSign(stakeAccount);
            const signature = await sendTransaction(transaction, connection, { minContextSlot });
            const conf = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
            if (conf.value.err == null) {
                const balance = await connection.getBalance(publicKey);
                // const balance = await solana.getBalance(new PublicKey(publicKey));;
                setResult("Transaction successful!");
                setTxHash(signature);
                setUserBalance(balance);
            } else {
                setResult("Transaction failed to send.")
            }
        } catch (e) {
            if (e.error && e.error.code === 4001) {
                setResult("Transaction rejected in wallet.");
            } else {
                console.log(e);
                setResult("An unknown error occurred. Please check your wallet history.")
            }
        }
    }, [publicKey, sendTransaction, connection, stakeAmount, setResult, setTxHash]);

    return (
        <View style={styles.stakingCard}>
            <View style={styles.balanceContainer}>
                <Text style={styles.balanceLabel}>Your SOL Balance</Text>
                <Text key={`${pubkey}-${userBalance}`} style={styles.balanceValue}>{userBalance === null ? 0.1 : (userBalance / 1e9).toFixed(5)} SOL</Text>
            </View>
            <View style={styles.inputContainer}>
                <TextInput
                    style={styles.input}
                    placeholder="0"
                    value={stakeAmount}
                    onChangeText={setStakeAmount}
                    keyboardType="numeric"
                    inputMode="numberic"
                />
                <TouchableOpacity style={styles.maxButton} onPress={handleMaxPress}>
                    <Text style={styles.maxButtonText}>Max</Text>
                </TouchableOpacity>
                <Text style={styles.inputSuffix}>SOL</Text>
            </View>
            <View style={styles.apyContainer}>
                <Text style={styles.apyLabel}>Estimated APY</Text>
                <Text key={apy} style={styles.apyValue}>{apy}%</Text>
            </View>
            <TouchableOpacity disabled={pubkey === null || stakeAmount === 0} onPress={handleStakePress} style={[styles.stakeButton, pubkey === null || stakeAmount === 0 ? styles.disabledButton : null]}>
                <Text style={styles.stakeButtonText}>Stake</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(255,123,34,0.2)',
        zIndex: 2,
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerLogo: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{ rotate: '-45deg' }],
    },
    connectButton: {
        borderWidth: 1,
        borderColor: '#FF8400',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    connectButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
    },
    scrollView: {
        flex: 1,
    },
    scrollViewContent: {
        flexGrow: 1,
        padding: 16,
        alignItems: 'center',
    },
    main: {
        padding: 16,
        alignItems: 'center',
    },
    whiteTitle: {
        fontSize: 36,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: 24,
    },
    title: {
        fontSize: 36,
        fontFamily: 'PP Neue Machina Plain',
        color: '#FF8400',
        textAlign: 'center',
        marginBottom: 24,
    },
    stakingCard: {
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 16,
        padding: 24,
        width: '100%',
        maxWidth: 400,
    },
    resultContainer: {
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 16,
        padding: 16,
        width: '100%',
        maxWidth: 345,
        alignItems: 'center',
    },
    resultWrapper: {
        padding: 16,
        alignItems: 'center',
    },
    balanceContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    balanceLabel: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    balanceValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    inputContainer: {
        position: 'relative',
        marginBottom: 16,
    },
    input: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderWidth: 1,
        borderColor: 'rgba(255,123,34,0.5)',
        borderRadius: 8,
        color: 'white',
        fontSize: 18,
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    maxButton: {
        position: 'absolute',
        right: 50,
        top: 12,
        backgroundColor: 'rgba(255,123,34,0.3)',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
    },
    maxButtonText: {
        color: '#FF8400',
        fontWeight: 'bold',
        fontFamily: 'PP Neue Machina Plain',
        fontSize: 14,
    },
    inputSuffix: {
        position: 'absolute',
        right: 16,
        top: 17,
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    apyLabel: {
        color: '#FF8400',
        fontFamily: 'PP Neue Machina Plain',
    },
    apyValue: {
        color: 'white',
        fontSize: 20,
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#FF8400',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 12,
        borderRadius: 8,
    },
    disabledButton: {
        opacity: 0.5,
    },
    stakeButtonText: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'PP Neue Machina Plain',
        marginRight: 8,
    },
    stakeButtonIcon: {
        marginLeft: 8,
    },
    sectionTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#FF8400',
        textAlign: 'center',
        marginTop: 32,
        marginBottom: 16,
    },
    featuresContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    featureCard: {
        backgroundColor: 'rgba(255,123,34,0.05)',
        borderRadius: 12,
        padding: 16,
        margin: 8,
        width: '30%',
        minWidth: 200,
        alignItems: 'center',
    },
    featureIcon: {
        marginBottom: 8,
    },
    featureTitle: {
        color: '#FF8400',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign: 'center',
    },
    featureDescription: {
        color: 'rgba(255,255,255,0.7)',
        textAlign: 'center',
    },
    footer: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(255,123,34,0.2)',
        padding: 16,
    },
    footerNav: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 8,
    },
    footerLink: {
        color: 'rgba(255,255,255,0.7)',
        marginHorizontal: 8,
    },
    footerText: {
        color: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
    },
});