import { PublicKey, Keypair, Connection, StakeProgram, ComputeBudgetProgram, LAMPORTS_PER_SOL, Transaction } from '@solana/web3.js';

export default class Solana {
    constructor(url) {
        this.rpc = new Connection(url, 'confirmed');
        this.voteAccount = "oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe";
    }

    async getBalance(wallet) {
        if (wallet) {
            return await this.rpc.getBalance(wallet);
        }
        return null;
    }

    async stakeSol(amount, pubkey) {
        try {
            const wallet = new PublicKey(pubkey);
            const stakeAccount = Keypair.generate();
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            const stakeAccountIX = StakeProgram.createAccount({
                fromPubkey: wallet,
                stakePubkey: stakeAccount.publicKey,
                authorized: {
                    staker: wallet,
                    withdrawer: wallet,
                },
                lamports: amount * LAMPORTS_PER_SOL,
            });
            const delegateIX = StakeProgram.delegate({
                stakePubkey: stakeAccount.publicKey,
                authorizedPubkey: wallet,
                votePubkey: new PublicKey(this.voteAccount),
            });
            const { blockhash, lastValidBlockHeight } = await this.rpc.getLatestBlockhash();
            const transaction = new Transaction({
                feePayer: wallet,
                blockhash,
                lastValidBlockHeight,
            })
                .add(priorityFeeIX)
                .add(stakeAccountIX)
                .add(delegateIX);
            const provider = window.solana;
            transaction.partialSign(stakeAccount);
            const signedTransaction = await provider.signTransaction(transaction);
            const txHash = await this.rpc.sendRawTransaction(signedTransaction.serialize());
            return { status: 'success', hash: txHash };
        } catch (e) {
            return { status: 'error', error: e };
        }
    }

    async getMinimumBalanceForRentExemption() {
        return await this.rpc.getMinimumBalanceForRentExemption(StakeProgram.space);
    }

    async fetchStakeAccount(walletPubkey) {
        const epochInfo = await this.rpc.getEpochInfo();
        const currentEpoch = epochInfo.epoch;
        const validatorInfo = await this.fetchValidatorInfo();
        // Get the list of stake accounts
        const rawStakeAccounts = await this.rpc.getParsedProgramAccounts(
            new PublicKey('Stake11111111111111111111111111111111111111'), // Solana Stake Program ID
            {
                filters: [
                    {
                        memcmp: {
                            offset: 12, // The offset where the stake authority's public key is stored
                            bytes: walletPubkey,
                        },
                    },
                ],
            }
        );
        // Log or return the stake accounts
        const stakeAccounts = rawStakeAccounts.map(account => {
            const stakeData = account.account.data.parsed.info.stake.delegation;
            let state = 'Inactive';

            if (stakeData) {
                let { stake, activationEpoch, deactivationEpoch } = stakeData;
                activationEpoch = parseFloat(activationEpoch);
                deactivationEpoch = parseFloat(deactivationEpoch);
                if (stake > 0) {
                    if (activationEpoch < currentEpoch && (deactivationEpoch === '18446744073709551615' || deactivationEpoch > currentEpoch)) {
                        state = 'Active';
                    } else if (activationEpoch === currentEpoch) {
                        state = 'Activating';
                    } else if (deactivationEpoch === currentEpoch && deactivationEpoch !== '18446744073709551615') {
                        state = 'Deactivating';
                    }
                }
            }
            const voteAccount = account.account.data.parsed.info.stake.delegation.voter;
            return {
                name: validatorInfo.find(info => info.voteAccount === voteAccount).name,
                iconUrl: validatorInfo.find(info => info.voteAccount === voteAccount).iconUrl,
                pubkey: account.pubkey.toString(),
                voteAccount: voteAccount,
                lamports: account.account.lamports,
                state: state,
            }
        });
        return stakeAccounts;
    }

    async fetchValidatorInfo() {
        try {
            // Fetch the validator info accounts
            const accounts = await this.rpc.getParsedProgramAccounts(
                new PublicKey("Config1111111111111111111111111111111111111"),
                {
                    filters: [
                        { memcmp: { offset: 1, bytes: new PublicKey("Va1idator1nfo111111111111111111111111111111") } },
                    ]
                }
            );
            // Fetch the vote accounts from Solana
            const voteAccountsData = await this.rpc.getVoteAccounts();
            const allVoteAccounts = voteAccountsData.current.concat(voteAccountsData.delinquent);

            const validators = accounts.map(account => {
                if (!account.account || !account.account.data || !account.account.data.parsed) {
                    return {};
                }
                const identityKey = account.account.data.parsed.info.keys[1].pubkey;
                const voteAccountInfo = allVoteAccounts.find(voteAccount => voteAccount.nodePubkey === identityKey);
                const info = account.account.data.parsed.info.configData;


                // Match the vote account for this identity key

                let iconUrl = null;
                if (info.iconUrl) {
                    iconUrl = info.iconUrl;
                }

                return {
                    identityKey,
                    voteAccount: voteAccountInfo ? voteAccountInfo.votePubkey : null,
                    name: info.name,
                    iconUrl: iconUrl,
                };
            });

            return validators;
        } catch (error) {
            console.error('Error fetching validator info:', error);
            return [];
        }
    }
}