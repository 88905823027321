import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native-web';



export default function StakeUnstakeToggle({ toggleStakeUnstake, isStaking }) {
    return (
        <View style={styles.toggleContainer}>
            <TouchableOpacity
                style={[styles.toggleButton, isStaking && styles.toggleButtonActive]}
                onPress={() => toggleStakeUnstake(true)}
            >
                <Text style={[styles.toggleButtonText, isStaking && styles.toggleButtonTextActive]}>Stake</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.toggleButton, !isStaking && styles.toggleButtonActive]}
                onPress={() => toggleStakeUnstake(false)}
            >
                <Text style={[styles.toggleButtonText, !isStaking && styles.toggleButtonTextActive]}>Unstake</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    toggleContainer: {
        flexDirection: 'row',
        backgroundColor: 'rgba(255,123,34,0.1)',
        borderRadius: 8,
        marginBottom: 24,
    },
    toggleButton: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    toggleButtonActive: {
        backgroundColor: '#ff7b22',
    },
    toggleButtonText: {
        color: '#ff7b22',
        fontFamily: 'PP Neue Machina Plain',
    },
    toggleButtonTextActive: {
        color: 'black',
    }
});