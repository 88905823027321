import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { SafeAreaView, SafeAreaProvider } from 'react-native-safe-area-context';

const LoadingSpinner = () => (
    <SafeAreaProvider>
        <SafeAreaView style={[styles.container, styles.horizontal]}>
            <ActivityIndicator size="large" color="rgba(255,123,34,0.2)" />
        </SafeAreaView>
    </SafeAreaProvider>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    horizontal: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
    },
});

export default LoadingSpinner;