import '@solana/wallet-adapter-react-ui/styles.css';
import './App.css';
import HomeScreen from './screens/HomeScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Image, StyleSheet, View, TouchableOpacity, Linking } from 'react-native-web';
import ConnectWallet from './components/ConnectWallet';
import {
    ConnectionProvider,
    WalletProvider,
} from '@solana/wallet-adapter-react';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';

const handleHomePress = () => {
    Linking.openURL('https://www.orangefin.ventures');
};

const network = "https://mainnet.helius-rpc.com/?api-key=26dd16db-a14e-4393-a04f-fe0fc72db377"; // Use 'mainnet-beta' for mainnet
const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter()];

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black',
    },
    header: {
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(255,123,34,0.2)',
        backgroundColor: 'black',
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
    },
    logo: {
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
    connectWallet: {
        padding: 16,
    }
});

const headerOptions = {
    headerStyle: styles.header,
    headerTintColor: '#fff',
    headerLeft: () => (
        <View style={styles.logoContainer}>
            <TouchableOpacity onPress={handleHomePress}>
                <Image defaultSource="https://img.orangefin.ventures/orangefin_full_black.png" style={styles.logo}></Image>
            </TouchableOpacity>
        </View>
    ),
    headerRight: () => (
        <View style={styles.connectWallet}>
            <ConnectWallet style={styles.connectWallet} />
        </View>
    ),
    headerTitle: "",
};

const Stack = createNativeStackNavigator({
    initialRouteName: 'Stake',
    screens: {
        Stake: HomeScreen,
    },
    screenOptions: {
        headerStyle: styles.header,
        headerTintColor: '#fff',
        headerLeft: () => (
            <View style={styles.logoContainer}>
                <TouchableOpacity onPress={handleHomePress}>
                    <Image defaultSource="https://img.orangefin.ventures/orangefin_full_black.png" style={styles.logo}></Image>
                </TouchableOpacity>
            </View>
        ),
        headerRight: () => (
            <View style={styles.connectWallet}>
                <ConnectWallet style={styles.connectWallet} />
            </View>
        ),
        headerTitle: false,
    },
});

export default function App() {
    return (
        <ConnectionProvider endpoint={network}>
            < WalletProvider wallets={wallets} autoConnect >
                <WalletModalProvider>
                    <NavigationContainer>
                        <Stack.Navigator>
                            <Stack.Screen
                                name="Stake"
                                component={HomeScreen}
                                options={headerOptions}
                            />
                        </Stack.Navigator>
                    </NavigationContainer>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};