import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { useWalletAddress } from '../hooks/wallet';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const ConnectWalletButton = () => {
    useWalletAddress();
    return (
        <TouchableOpacity style={styles.container}>
            <View style={{ position: 'relative' }}>
                <WalletMultiButton style={styles.connectButton}></WalletMultiButton>
            </View>
        </TouchableOpacity>
    );
};

export default ConnectWalletButton;

const styles = StyleSheet.create({
    container: {
    },
    connectButton: {
        borderWidth: 1,
        borderColor: '#FF8400',
        backgroundColor: '#FF8400',
        color: '#000000',
        paddingVertical: 8,
        paddingHorizontal: 16,
        fontFamily: 'PP Neue Machina Plain',
        borderRadius: 8,
        textAlign: 'center',
    }
});