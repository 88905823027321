import React, { useCallback, useState, useEffect } from 'react';
import { Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native-web';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, StakeProgram, ComputeBudgetProgram, Transaction } from '@solana/web3.js';
import Solana from '../api/solana';
import LoadingSpinner from './LoadingSpinner';

export default function StakeAccounts({ minimumRent }) {
    const { publicKey, sendTransaction } = useWallet();
    const { connection } = useConnection();
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        const fetchStakeAccounts = async () => {
            const solana = new Solana("https://mainnet.helius-rpc.com/?api-key=26dd16db-a14e-4393-a04f-fe0fc72db377");
            if (publicKey) {
                const stakeAccounts = await solana.fetchStakeAccount(publicKey);
                setAccounts(stakeAccounts);
            }
        };

        fetchStakeAccounts();
    }, [accounts, publicKey]);

    const fetchActivationStyle = (state) => {
        let style = null;
        if (state === 'Active') {
            style = styles.green;
        } else if (state === 'Activating' || state === 'Deactivating') {
            style = styles.yellow;
        } else {
            style = styles.red;
        }
        return style;
    };

    const setDisabled = (state) => {
        let status = true;
        if (state === 'Active') {
            status = false;
        } else if (state === 'Activating' || state === 'Deactivating') {
            status = true;
        }
        return status;
    };

    const setButtonText = (state) => {
        let status = 'Unstake';
        if (state === 'Inactive') {
            status = 'Withdraw';
        } else {
            status = 'Unstake';
        }
        return status;
    };

    const handleButtonPress = useCallback(async (stakeAccountPubkey, state, lamports) => {
        try {
            const solana = new Solana("https://mainnet.helius-rpc.com/?api-key=26dd16db-a14e-4393-a04f-fe0fc72db377");
            const priorityFeeIX = ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 10000 });
            let stakeIX;
            if (state === 'Active') {
                stakeIX = StakeProgram.deactivate({
                    stakePubkey: new PublicKey(stakeAccountPubkey),
                    authorizedPubkey: publicKey,
                });
            } else if (state === 'Inactive') {
                stakeIX = StakeProgram.withdraw({
                    stakePubkey: new PublicKey(stakeAccountPubkey),
                    authorizedPubkey: publicKey,
                    toPubkey: publicKey,
                    lamports,
                });
            }
            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight }
            } = await connection.getLatestBlockhashAndContext();
            const transaction = new Transaction({
                feePayer: publicKey,
                blockhash,
                lastValidBlockHeight,
            })
                .add(priorityFeeIX)
                .add(stakeIX);
            const signature = await sendTransaction(transaction, connection, { minContextSlot });
            const conf = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
            if (conf.value.err == null) {
                let newState = "";
                if (state === 'Active') {
                    newState = 'Deactivating';
                } else if (state === 'Inactive') {
                    newState = 'Withdrawn'
                }
                const updatedAccounts = accounts.map(account =>
                    account.pubkey === stakeAccountPubkey
                        ? { ...account, state: newState }
                        : account
                );
                setAccounts(updatedAccounts);
                const stakeAccounts = await solana.fetchStakeAccount(publicKey);
                setAccounts(stakeAccounts);
            } else {
                console.log(conf.value.err);
            }
        } catch (e) {
            console.log(e);
        }
    }, [publicKey, sendTransaction, connection, accounts]);

    return publicKey !== null ? (
        <View styles={styles.container}>
            {accounts.length === 0 ? (
                <LoadingSpinner />
            ) : accounts.map((account, index) => (
                <View key={index} style={styles.stakeAccount}>
                    <View style={styles.imageContainer}>
                        {account.iconUrl ? (
                            <Image defaultSource={account.iconUrl} style={styles.logo} />
                        ) : (
                            <View style={[styles.logo, styles.emptyLogo]}>
                                <Text style={styles.emptyLogoText}>V</Text>
                            </View>
                        )}

                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.stakeAccountText}>{account.name}</Text>
                        <Text style={[styles.stakeAccountText, styles.textRight]}>{((account.lamports - minimumRent) / 1e9).toFixed(5)} SOL</Text>
                        <Text style={[styles.stakeAccountText, styles.textRight, fetchActivationStyle(account.state)]}>{account.state}</Text>
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => handleButtonPress(account.pubkey, account.state, account.lamports)} style={[styles.stakeButton, setDisabled(account.state) === true ? styles.disabledButton : null]} disabled={setDisabled(account.state)}>
                            <Text style={styles.blackText}>{setButtonText(account.state)}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            ))}

        </View>
    ) : (
        <View styles={styles.container}>
            <Text style={styles.stakeAccountText}>Please connect your wallet to continue.</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {},
    stakeAccount: {
        borderWidth: 2,
        borderColor: 'rgba(255,123,34,0.2)',
        maxWidth: 400,
        borderRadius: 25,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1,
        flexGrow: 1,
        alignItems: 'center',
        marginBottom: 10,
    },
    stakeAccountText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'right',
        marginBottom: 2,
    },
    emptyLogoText: {
        color: 'white',
        fontFamily: 'PP Neue Machina Plain',
    },
    textRight: {
        textAlign: 'right'
    },
    logo: {
        width: 48,
        height: 48,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyLogo: {
        backgroundColor: 'grey',
    },
    imageContainer: {
        paddingRight: 30,
        alignItems: 'left',
    },
    infoContainer: {
        paddingRight: 30,
        width: 200,
    },
    blackText: {
        color: 'black',
        fontFamily: 'PP Neue Machina Plain',
    },
    stakeButton: {
        backgroundColor: '#FF8400',
        flexDirection: 'row',
        justifyContent: 'center',
        borderColor: 'rgba(255,123,34,0.2)',
        borderWidth: 2,
        alignItems: 'center',
        padding: 12,
        borderRadius: 8,
    },
    disabledButton: {
        opacity: 0.5,
    },
    green: {
        color: 'green',
    },
    yellow: {
        color: 'yellow',
    },
    red: {
        color: 'red',
    }
});